import './SliderContentA.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { SliderContentASection } from '../contentTypes';
import { Section } from '../Section';
import { Slider } from './Slider';

export interface SliderContentAProps extends SliderContentASection {
  backgroundSection?: string;
}

export const SliderContentA = ({ backgroundSection, itemList, subtitle, title }: SliderContentAProps) => {
  const [, setRef, refVisible] = b2x.useIntersectionObserver();
  const [isSliderInViewPort, setIsSliderInViewPort] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (refVisible) {
      setIsSliderInViewPort(true);
    }
  }, [refVisible]);

  return (
    <Section
      className={classnames('slider-content-a', backgroundSection)}
      noMarginY
      noPaddingX
      subtitle={subtitle}
      title={title}
    >
      {itemList && (
        <b2x.EqualHeight>
          <div ref={setRef}>
            <Slider
              className="pb-3 pb-lg-4"
              responsive={{
                lg: {
                  slidesPerView: 6.4,
                },
                md: {
                  slidesPerView: 4.4,
                },
                sm: {
                  slidesPerView: 3.8,
                },
                xl: {
                  slidesPerView: itemList.length,
                },
              }}
              slidesPerView={2.4}
              spaceBetween={20}
            >
              {itemList.map((item) => (
                <SwiperSlide
                  className={classnames('fade-in-image-animation-container', {
                    visible: isSliderInViewPort,
                  })}
                  key={item.contentSectionId}
                >
                  <div className="slide text-center">
                    <b2x.EqualHeightElement name="image">
                      {item.cta ? (
                        <b2x.DeprecatedCta cta={item.cta}>
                          <b2x.ImageFromContent {...item.img} className="mb-3 fade-in-image-animation" fluid />
                        </b2x.DeprecatedCta>
                      ) : (
                        <b2x.ImageFromContent {...item.img} className="mb-3 fade-in-image-animation" fluid />
                      )}
                    </b2x.EqualHeightElement>

                    {item.title && (
                      <b2x.EqualHeightElement name="title">
                        <h5 className="fw-bold mb-2 text-blue">{b2x.formatHtml(item.title)}</h5>
                      </b2x.EqualHeightElement>
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Slider>
          </div>
        </b2x.EqualHeight>
      )}
    </Section>
  );
};
