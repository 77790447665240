import { b2x } from '@b2x/react/src';

import { CategoryProductPreviewContentType } from './contentTypes';

export interface CategoryProductPreviewProps extends CategoryProductPreviewContentType {}

export const CategoryProductPreview = ({ cta, img, title }: CategoryProductPreviewProps) => {
  return cta ? (
    <b2x.DeprecatedCta className="category-product-preview text-reset text-decoration-none" cta={cta}>
      <b2x.ImageFromContent {...img} className="fade-in-image-animation" fluid />
      <h4 className="text-blue">{title}</h4>
    </b2x.DeprecatedCta>
  ) : null;
};
