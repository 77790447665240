import { b2x } from '@b2x/react/src';
import { Fragment } from 'react';

import { LandintTemplateAContentType } from '../contentTypes';
import { Page } from './Page';

export interface LandingTemplateAPageProps {}

export const LandingTemplateA = () => {
  const page = b2x.usePage<LandintTemplateAContentType>({ populate: { children: true } });

  const body = page?.content?.body;
  const currentBreakpoint = b2x.useBreakpoint();

  return (
    <Page
      noPaddingBottom
      noPaddingTop
      thingsToLoadBeforePageReady={[page]}
      transparentHeader={!b2x.untilBreakpoint('lg', currentBreakpoint)}
    >
      {body?.itemList?.map((item) => {
        const firstRow = item.firstRow;

        return (
          <Fragment key={item.contentSectionId}>
            <div className="position-relative text-center container-fluid container-fluid-w-custom g-0">
              <b2x.ImageFromContent {...firstRow?.img} fluid />
              <div
                className="position-absolute m-auto text-center text-uppercase"
                style={{ height: 'fit-content', inset: 0, width: 'fit-content', zIndex: 2 }}
              >
                <h3 className="text-blue fw-bold h1">{firstRow?.title}</h3>
                <h3 className="mt-3 text-blue h1">{b2x.formatHtml(firstRow?.subtitle)}</h3>
              </div>
            </div>

            <div className="container-fluid container-fluid-w-custom g-0">
              {item.rowList?.map((row) => (
                <b2x.Row gap={0} key={row.contentSectionId}>
                  {row.columnList?.map((column) => (
                    <b2x.Col key={column.contentSectionId} size={{ md: column.size, xs: 12 }}>
                      {column.content ? (
                        <div className={`bg-${column.background} h-100 d-flex align-items-center p-3 p-md-4 p-lg-5`}>
                          <h5 className="text-blue">{b2x.formatHtml(column.content)}</h5>
                        </div>
                      ) : column.img ? (
                        <b2x.ImageFromContent {...column.img} fluid />
                      ) : null}
                    </b2x.Col>
                  ))}
                </b2x.Row>
              ))}
            </div>
          </Fragment>
        );
      })}
    </Page>
  );
};
