import './DesktopHeader.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useAppContext, useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { HeaderContentType, HeaderMenuItemContentType, MiscellaneousContentType } from './contentTypes';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import logo from './images/logo.svg';
import logoSmall from './images/logo-small.svg';

export interface DesktopHeaderProps {
  activeCategory?: b2x.MenuApiDto;
  content?: b2x.ContentApiDto<HeaderContentType>;
  firstRowRef: React.RefObject<HTMLDivElement>;
  localeBoxVisible: boolean;
  recalculateHeaderHeight(): void;
  searchBoxVisible: boolean;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  setLocaleBoxVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchBoxVisible: React.Dispatch<React.SetStateAction<boolean>>;
  toggleLocaleBoxVisible(): void;
  visibleFrom: b2x.Breakpoint;
}

export const DesktopHeader = ({
  activeCategory,
  firstRowRef,
  localeBoxVisible,
  recalculateHeaderHeight,
  searchBoxVisible,
  setActiveCategory,
  setLocaleBoxVisible,
  setSearchBoxVisible,
  toggleLocaleBoxVisible,
  visibleFrom,
}: DesktopHeaderProps) => {
  const { setThingsToLoadBeforeAppReady } = b2x.useAppStaticContext();

  const menu = b2x.useMenu('MENU_HEADER_DESKTOP', { populate: { content: true } });

  React.useEffect(() => {
    setThingsToLoadBeforeAppReady((prevState) => ({ ...prevState, MENU_HEADER: menu }));
  }, [menu, setThingsToLoadBeforeAppReady]);

  const toggleSearchBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setLocaleBoxVisible(false);
    setSearchBoxVisible((prevState) => !prevState);
  }, [setActiveCategory, setLocaleBoxVisible, setSearchBoxVisible]);

  return (
    <FirstRow
      activeCategory={activeCategory}
      innerRef={firstRowRef}
      localeBoxVisible={localeBoxVisible}
      menu={menu}
      recalculateHeaderHeight={recalculateHeaderHeight}
      searchBoxVisible={searchBoxVisible}
      setActiveCategory={setActiveCategory}
      toggleLocaleBoxVisible={toggleLocaleBoxVisible}
      toggleSearchBoxVisible={toggleSearchBoxVisible}
      visibleFrom={visibleFrom}
    />
  );
};

interface FirstRowProps {
  activeCategory?: b2x.MenuApiDto;
  innerRef: React.RefObject<HTMLDivElement>;
  localeBoxVisible: boolean;
  menu?: b2x.MenuApiDto;
  recalculateHeaderHeight(): void;
  searchBoxVisible: boolean;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  toggleLocaleBoxVisible(): void;
  toggleSearchBoxVisible(): void;
  visibleFrom: b2x.Breakpoint;
}

const FirstRow = ({
  activeCategory,
  innerRef,
  localeBoxVisible,
  menu,
  recalculateHeaderHeight,
  searchBoxVisible,
  setActiveCategory,
  toggleLocaleBoxVisible,
  toggleSearchBoxVisible,
  visibleFrom,
}: FirstRowProps) => {
  const { transparentHeader } = useAppContext();
  const subMenuDropdownRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classnames('header-first-row-wrapper', `d-none d-${visibleFrom}-block`, {
        'show-white-background': searchBoxVisible || localeBoxVisible || activeCategory,
        transparent: transparentHeader && activeCategory === undefined && !localeBoxVisible && !searchBoxVisible,
      })}
      ref={innerRef}
    >
      <div className={classnames('header-first-row', 'pt-3')}>
        <Container>
          <b2x.Row className="justify-content-center">
            <b2x.Col size={12}>
              <b2x.Row gap={0}>
                <b2x.Col size={'auto'}>
                  <div className="header-logo-col h-100 py-2 px-4 d-flex align-items-center bg-white shadow-sm">
                    <Logo recalculateHeaderHeight={recalculateHeaderHeight} />
                  </div>
                </b2x.Col>
                <b2x.Col>
                  <div className="header-menu-col h-100 px-4 d-flex align-items-center bg-secondary shadow-sm">
                    <b2x.Row className="w-100 h-100">
                      <b2x.Col className="d-flex flex-grow-0">
                        <Menu
                          activeCategory={activeCategory}
                          menu={menu}
                          setActiveCategory={setActiveCategory}
                          subMenuDropdownRef={subMenuDropdownRef}
                        />
                      </b2x.Col>
                      <b2x.Col className="d-flex align-items-center justify-content-end py-3">
                        <Toggles toggleSearchBoxVisible={toggleSearchBoxVisible} />
                      </b2x.Col>
                    </b2x.Row>
                  </div>
                </b2x.Col>
              </b2x.Row>
            </b2x.Col>
          </b2x.Row>
        </Container>
      </div>
      {activeCategory && (
        <SubMenuDropdown
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          subMenuDropdownRef={subMenuDropdownRef}
        />
      )}
      {searchBoxVisible && <SearchBox toggleSearchBoxVisible={toggleSearchBoxVisible} />}
      {localeBoxVisible && <LocaleBox toggleLocaleBoxVisible={toggleLocaleBoxVisible} />}
    </div>
  );
};

interface LocaleToggleProps {
  toggleLocaleBoxVisible(): void;
}

export const LocaleToggle = ({ toggleLocaleBoxVisible }: LocaleToggleProps) => {
  const { locale, shippingCountry } = b2x.useAppContext();

  return (
    <Button
      alignItems="center"
      className="text-decoration-none fw-normal text-reset"
      iconStart={{ name: 'language', size: 25 }}
      label={
        locale &&
        shippingCountry && (
          <span>{t('header.sendTo', { country: shippingCountry.name, locale: locale.languageDescriptionLocal })}</span>
        )
      }
      onClick={toggleLocaleBoxVisible}
      size="small"
      variant="blank"
    />
  );
};

interface TogglesProps {
  toggleSearchBoxVisible(): void;
}

const Toggles = ({ toggleSearchBoxVisible }: TogglesProps) => {
  const { session } = b2x.useAppContext();
  const { getPagePath } = b2x.useAppStaticContext();

  const { showAccountOffcanvas, showCartOffcanvas } = useAppStaticContext();

  return (
    <>
      <div className="hstack gap-2">
        <Button
          className="text-white"
          iconEnd={{ name: 'search', size: 25 }}
          onClick={toggleSearchBoxVisible}
          variant="blank"
        />
        {session?.customer ? (
          <b2x.router.Link className="lh-1 text-primary" to={getPagePath('SITE_ACCOUNT')}>
            <Icon name="profile" size={25} />
          </b2x.router.Link>
        ) : (
          <Button
            className="text-white"
            iconEnd={{ name: 'profile', size: 25 }}
            onClick={showAccountOffcanvas}
            variant="blank"
          />
        )}

        {b2x.appConfig.enableWishlist &&
          (session?.customer ? (
            <b2x.router.Link className="lh-1" to="/account/area/wishlist">
              <Button className="position-relative text-white" variant="blank">
                <Icon name={'wishlist'} size={25} />
                {((session.wishlist?.products && session.wishlist.products.length > 0) ||
                  (session.wishlist?.skus && session.wishlist.skus.length > 0)) && (
                  <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
                    {(session.wishlist.products ? session.wishlist.products.length : 0) +
                      (session.wishlist.skus ? session.wishlist.skus.length : 0)}
                  </span>
                )}
              </Button>
            </b2x.router.Link>
          ) : (
            <Button
              className="text-white"
              iconEnd={{ name: 'wishlist', size: 25 }}
              onClick={showAccountOffcanvas}
              variant="blank"
            />
          ))}

        <Button className="position-relative text-white" onClick={showCartOffcanvas} variant="blank">
          <Icon name={'cart'} size={25} />
          {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
            <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
              {session.cart.itemsNumber}
            </span>
          )}
        </Button>
      </div>
    </>
  );
};

interface LogoProps {
  recalculateHeaderHeight(): void;
  small?: boolean;
}

const Logo = ({ recalculateHeaderHeight, small }: LogoProps) => {
  return (
    <b2x.router.Link to="/">
      <b2x.Image onLoad={recalculateHeaderHeight} src={small ? logoSmall : logo} />
    </b2x.router.Link>
  );
};

interface MenuItemContainerProps extends React.PropsWithChildren {
  activeCategory?: b2x.MenuApiDto;
  firstLevelCategory: b2x.MenuApiDto;
  menuItemRef?: React.RefObject<HTMLDivElement>;
}

const MenuItemContainer = ({ activeCategory, children, firstLevelCategory, menuItemRef }: MenuItemContainerProps) => {
  return (
    <div
      className={classnames('menu-item d-flex align-items-center px-2', {
        active: activeCategory && activeCategory.id === firstLevelCategory.id,
      })}
      ref={menuItemRef}
    >
      {children}
    </div>
  );
};

interface DropdownMenuButtonProps {
  activeCategory?: b2x.MenuApiDto;
  addRef(ref: React.RefObject<HTMLDivElement>): void;
  firstLevelCategory: b2x.MenuApiDto;
  removeRef(ref: React.RefObject<HTMLDivElement>): void;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
}

const DropdownMenuButton = ({
  activeCategory,
  addRef,
  firstLevelCategory,
  removeRef,
  setActiveCategory,
}: DropdownMenuButtonProps) => {
  const menuItemRef = useRef<HTMLDivElement>(null);
  const timeoutOnActivateCategory = useRef<NodeJS.Timeout>();

  useEffect(() => {
    addRef(menuItemRef);

    return () => removeRef(menuItemRef);
  }, [addRef, removeRef]);

  const onActivateCategory = useCallback(() => {
    timeoutOnActivateCategory.current = setTimeout(() => setActiveCategory(firstLevelCategory), 250);
  }, [setActiveCategory, firstLevelCategory]);

  const onDeactivateCategory = useCallback(() => clearTimeout(timeoutOnActivateCategory.current), []);
  useEffect(() => onDeactivateCategory, [onDeactivateCategory]);

  return (
    <MenuItemContainer
      activeCategory={activeCategory}
      firstLevelCategory={firstLevelCategory}
      menuItemRef={menuItemRef}
    >
      <Button
        className={classnames('text-decoration-none fw-bold text-white p-0 text-nowrap', {
          active: activeCategory && activeCategory.id === firstLevelCategory.id,
        })}
        label={firstLevelCategory.label}
        onClick={onActivateCategory}
        onMouseEnter={onActivateCategory}
        onMouseLeave={onDeactivateCategory}
        size="small"
        variant="blank"
      />
    </MenuItemContainer>
  );
};

interface MenuProps {
  activeCategory?: b2x.MenuApiDto;
  menu?: b2x.MenuApiDto;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const Menu = ({ activeCategory, menu, setActiveCategory, subMenuDropdownRef }: MenuProps) => {
  const idActiveCategory = activeCategory?.id;
  const onHoverOutsideCallback = useCallback(() => {
    if (idActiveCategory) {
      setActiveCategory(undefined);
    }
  }, [idActiveCategory, setActiveCategory]);

  const { addRef, removeRef } = b2x.useOnHoverOutside(onHoverOutsideCallback, [subMenuDropdownRef], 100);

  return (
    <div className="menu d-flex">
      {menu?.children.map(
        (firstLevelCategory) =>
          (firstLevelCategory.children.length > 0 || firstLevelCategory.link !== undefined) &&
          (firstLevelCategory.children.length > 0 ? (
            <DropdownMenuButton
              activeCategory={activeCategory}
              addRef={addRef}
              firstLevelCategory={firstLevelCategory}
              key={firstLevelCategory.id}
              removeRef={removeRef}
              setActiveCategory={setActiveCategory}
            />
          ) : (
            <MenuItemContainer
              activeCategory={activeCategory}
              firstLevelCategory={firstLevelCategory}
              key={firstLevelCategory.id}
            >
              <b2x.router.Link
                className={classnames(
                  'Button btn btn-link blank btn-sm text-decoration-none text-white fw-semi-bold p-0 text-nowrap',
                  {
                    active: activeCategory && activeCategory.id === firstLevelCategory.id,
                  }
                )}
                to={firstLevelCategory.link}
              >
                {firstLevelCategory.label}
              </b2x.router.Link>
            </MenuItemContainer>
          ))
      )}
    </div>
  );
};

interface SubMenuDropdownProps {
  activeCategory: b2x.MenuApiDto;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const SubMenuDropdown = ({ activeCategory, setActiveCategory, subMenuDropdownRef }: SubMenuDropdownProps) => {
  const close = React.useCallback(() => {
    setActiveCategory(undefined);
  }, [setActiveCategory]);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 50);
    return () => clearTimeout(timeout);
  }, []);

  b2x.useKeyPress('Escape', close);

  return (
    <>
      <div
        className={classnames({ mounted: isMounted }, 'sub-menu-dropdown shadow-sm w-100 py-5')}
        ref={subMenuDropdownRef}
      >
        <Container>
          <b2x.Row className="justify-content-center" cols={'auto'}>
            {activeCategory.children.map((secondLevelCategory, index) => (
              <b2x.Col
                className={classnames(
                  ' flex-column px-2 px-xxl-4',
                  activeCategory.children.length - 1 === index && activeCategory.children.length === 6
                    ? 'd-none d-xl-flex'
                    : 'd-flex'
                )}
                key={secondLevelCategory.id}
              >
                {secondLevelCategory.children.map((thirdLevelCategory) => (
                  <div key={thirdLevelCategory.id} style={{ maxWidth: '210px' }}>
                    {thirdLevelCategory.content &&
                      b2x.typedContent<HeaderMenuItemContentType>(thirdLevelCategory.content, (content) => (
                        <>
                          {content.body.img?.src?.xs && (
                            <div className="mb-4">
                              <b2x.router.Link to={content.body.cta?.to?.href}>
                                <b2x.ImageFromContent fluid {...content.body.img} />
                              </b2x.router.Link>
                            </div>
                          )}
                          {content.body.cta?.label && (
                            <div className="">
                              <b2x.DeprecatedCta
                                className="fw-bold text-decoration-none h6 text-uppercase mb-3 d-block"
                                cta={content.body.cta}
                              >
                                {content.body.cta.label}
                              </b2x.DeprecatedCta>
                            </div>
                          )}
                        </>
                      ))}

                    {thirdLevelCategory.children.length === 0 && thirdLevelCategory.link && (
                      <h6 className="mt-3">
                        <b2x.router.Link className="" onClick={close} to={thirdLevelCategory.link}>
                          {thirdLevelCategory.label}
                        </b2x.router.Link>
                      </h6>
                    )}

                    {thirdLevelCategory.children.map((fourLevelCategory) => (
                      <div key={fourLevelCategory.id}>
                        {fourLevelCategory.children.length > 0 ? (
                          <ul className="list-unstyled">
                            <div className="mb-3">
                              <h6 className="text-uppercase fw-bold ">
                                <b2x.router.Link
                                  className=" text-decoration-none"
                                  onClick={close}
                                  to={fourLevelCategory.link}
                                >
                                  {fourLevelCategory.label}
                                </b2x.router.Link>
                              </h6>
                            </div>
                            {fourLevelCategory.children.map(
                              (fifthLevelCategory) =>
                                fifthLevelCategory.link && (
                                  <li key={fifthLevelCategory.id}>
                                    <b2x.router.Link
                                      className="text-reset text-decoration-none"
                                      onClick={close}
                                      to={fifthLevelCategory.link}
                                    >
                                      <span>{fifthLevelCategory.label}</span>
                                    </b2x.router.Link>
                                  </li>
                                )
                            )}
                          </ul>
                        ) : (
                          <h6
                            className={classnames(
                              thirdLevelCategory.tags?.includes('isProductList') ? '' : 'text-uppercase fw-bold mb-3'
                            )}
                          >
                            <b2x.router.Link
                              className={classnames(
                                'text-decoration-none',
                                thirdLevelCategory.tags?.includes('isProductList') ? 'text-reset' : ''
                              )}
                              onClick={close}
                              to={fourLevelCategory.link}
                            >
                              {fourLevelCategory.label}
                            </b2x.router.Link>
                          </h6>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </b2x.Col>
            ))}
          </b2x.Row>
        </Container>
      </div>
    </>
  );
};

interface SearchBoxProps {
  toggleSearchBoxVisible(): void;
}

const SearchBox = ({ toggleSearchBoxVisible }: SearchBoxProps) => {
  return (
    <Box onCloseButtonClick={toggleSearchBoxVisible}>
      <div className="row justify-content-center">
        <div className="col-8">
          <b2x.SimpleSearchForm onSuccess={toggleSearchBoxVisible} />
        </div>
      </div>
    </Box>
  );
};

interface LocaleBoxProps {
  toggleLocaleBoxVisible(): void;
}

const LocaleBox = ({ toggleLocaleBoxVisible }: LocaleBoxProps) => {
  const content = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');

  return (
    <Box onCloseButtonClick={toggleLocaleBoxVisible}>
      <div className="row justify-content-center text-center">
        <div className="col-5">
          <b2x.LocaleForm />
          {content?.body.alertChangeCountry && (
            <div className="pt-3">{b2x.formatHtml(content.body.alertChangeCountry)}</div>
          )}
        </div>
      </div>
    </Box>
  );
};

interface BoxProps {
  children?: React.ReactNode;
  onCloseButtonClick(): void;
}

const Box = ({ children, onCloseButtonClick }: BoxProps) => {
  b2x.useKeyPress('Escape', onCloseButtonClick);

  const ref = React.useRef<HTMLDivElement>(null);

  const handleOutsideClick = React.useCallback(() => {
    onCloseButtonClick();
  }, [onCloseButtonClick]);

  b2x.useOutsideClickHandler(ref, handleOutsideClick);

  return (
    <div className="box py-5" ref={ref}>
      <Container>
        <div className="d-flex justify-content-end">
          <Button iconEnd={{ name: 'delete', size: 25 }} onClick={onCloseButtonClick} variant="blank" />
        </div>
        {children}
      </Container>
    </div>
  );
};
