import { b2x } from '@b2x/react/src';
import classNames from 'classnames';

import { Container } from './Container';
import { CategoryItemRowContentType } from './contentTypes';

export interface CategoryItemRowProps extends CategoryItemRowContentType {}

export const CategoryItemRow = ({ background, cta, description, img, imgOrder, title }: CategoryItemRowProps) => {
  return (
    <div className={`bg-${background}`}>
      <Container>
        <b2x.Row className="py-5 px-3 px-lg-5">
          <b2x.Col
            className={classNames(`order-md-${imgOrder}`, 'order-2 mt-md-0 mt-5 d-flex align-items-center')}
            size={{ md: 6, xs: 12 }}
          >
            <b2x.ImageFromContent {...img} fluid />
          </b2x.Col>
          <b2x.Col
            className="order-1 d-flex align-items-start flex-column justify-content-center"
            size={{ md: 6, xs: 12 }}
          >
            <h3 className="display-1 text-blue">{b2x.formatHtml(title)}</h3>
            <p className="text-blue">{b2x.formatHtml(description)}</p>
            {cta && (
              <div className="d-flex justify-content-center justify-content-lg-start w-100">
                <b2x.DeprecatedCta
                  className={classNames('btn text-white mt-3 mt-lg-5', `btn-${cta.variant}`)}
                  cta={cta}
                >
                  {cta.label}
                </b2x.DeprecatedCta>
              </div>
            )}
          </b2x.Col>
        </b2x.Row>
      </Container>
    </div>
  );
};
