import './css/style.scss';
import './i18n/i18n';

import { b2x } from '@b2x/react/src';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './App';
import { BestPrice } from './BestPrice';
import { Button, ButtonVariant } from './Button';
import { CartSku } from './CartSku';
import { Container } from './Container';
import { NewsletterForm } from './forms/NewsletterForm';
import { en } from './i18n/locales/en';
import { it } from './i18n/locales/it';
import { IconName, IconSize } from './Icon';
import { LoadingOverlay } from './LoadingOverlay';
import { OffcanvasHeader } from './Offcanvas';
import { PriceBlock } from './PriceBlock';
import { ProductTile, ProductTileBasicInfo, ProductTileExtraInfo } from './ProductTile';
import { reportWebVitals } from './reportWebVitals';

b2x.init<ButtonVariant, IconName, IconSize>({
  analytics: {
    baim: {
      debug: false,
    },
    facebook: {
      debug: false,
      pixelId: '883950411766533',
      sendPageView: false,
    },
    googleAnalytics4: {
      debug: false,
      id: 'G-007G45YG59',
    },

    /*     googleTagManager: {
      debug: false,
      // id: 'G-007G45YG59',
      id: 'GTM-W88SCSK',
    },
 */
  },
  api: {
    apiKey: 'GHEDHHIAABGIBIAJEDFJFE',
    productPopulate: {
      alternativeImages: true,
      assembledComponents: {
        componentSkus: {
          price: true,
          sku: {
            attributes: true,
            measurement: true,
            price: true,
            product: {
              attributeGroups: true,
              attributes: true,
            },
          },
        },
      },
      attributeGroups: true,
      attributes: true,
      priceRange: true,
      skus: {
        attributes: true,
        measurement: true,
        price: true,
      },
      tags: true,
      variantProducts: {
        skus: {
          attributes: true,
          measurement: true,
          price: true,
        },
      },
    },
    productTilePopulate: {
      attributes: true,
      priceRange: true,
      skus: {
        attributes: true,
        measurement: true,
        price: true,
      },
      tags: true,
      variantProducts: {
        skus: {
          attributes: true,
          measurement: true,
          price: true,
        },
      },
    },
    sessionOptions: {
      populate: {
        cart: {
          packs: {
            cartSkus: {
              giftCard: true,
              sku: {
                attributes: true,
                product: {
                  attributes: true,
                },
              },
            },
          },
          shippingProfile: true,
        },
        customer: true,
        wishlist: { products: true, skus: { product: true } },
      },
    },
  },
  cookiebot: {
    id: 'bc9c9042-82a0-47c2-91df-5a070e3683a8',
  },
  enableBestPrice: true,
  enableWishlist: true,
  form: {
    buttons: {
      cancel: {
        defaultVariant: 'outline-primary',
      },
      submit: {
        defaultVariant: 'primary',
      },
    },
  },
  i18n: {
    en: en,
    it: it,
  },
  icons: {
    arrow: {
      left: 'small-arrow-left',
    },
    close: 'delete',
    configurator: 'attachment',
    download: 'download',
    info: 'info',
    logout: 'exit',
  },
  preRelease: true,
  projectName: 'gentilini',
  ui: 'bs5',
  variants: {
    AddressFieldset: 'A',
    BestPrice: BestPrice,
    Button: Button,
    CartAddressFromAddressBookForm: 'A',
    CartAddressesForm: 'A',
    CartSku: CartSku,
    ChangePasswordForm: 'A',
    Container: Container,
    CustomerAddressForm: 'A',
    CustomerForm: 'A',
    InvoiceFieldset: 'A',
    LoadingOverlay: LoadingOverlay,
    LocaleForm: 'A',
    LoginForm: 'A',
    NewsletterForm: NewsletterForm,
    OffcanvasHeader: OffcanvasHeader,
    OrderGuestForm: 'A',
    PriceBlock: PriceBlock,
    ProductTile: ProductTile,
    ProductTileBasicInfo: ProductTileBasicInfo,
    ProductTileExtraInfo: ProductTileExtraInfo,
    SearchFilters: 'A',
    SimpleSearchForm: 'A',
    TaxBenefitsForm: 'A',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <b2x.router.BrowserRouter>
      <b2x.ModalsContext>
        <App />
      </b2x.ModalsContext>
    </b2x.router.BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
