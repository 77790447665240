import { b2x } from '@b2x/react/src';
import React from 'react';

import { BlocksRowSection } from '../BlocksRowSection';
import { Container } from '../Container';
import { HomePageContentType } from '../contentTypes';
import { NewsletterForm } from '../forms/NewsletterForm';
import { t } from '../i18n/i18n';
import { LottieElement } from '../LottieElement';
import { BannerSlider } from '../slider/BannerSlider';
import { SliderContentA } from '../slider/SliderContentA';
import { SliderProducts } from '../slider/SliderProducts';
import { Page } from './Page';
import { usePopUpNewsletterModal } from './PopupNewsletterModal';

export interface HomePageProps {}

export const HomePage = (props: HomePageProps) => {
  //const { getFixedPagePath, getPagePath } = b2x.useAppStaticContext();

  const page = b2x.usePage<HomePageContentType>({
    secondStep: {
      populate: {
        products: b2x.appConfig.api?.productTilePopulate,
      },
    },
  });

  const { pageReady } = b2x.useAppContext();

  const [PopupNewsLetterModal, showPopupNewsLetterModal] = usePopUpNewsletterModal({
    onClose: () => {
      const modalClosedTimes = (b2x.storage.getNumber('homePageNewsletterModalClosedTimes', true) ?? 0) + 1;
      b2x.storage.setNumber('homePageNewsletterModalClosedTimes', modalClosedTimes, true);
    },
  });

  React.useEffect(() => {
    const newsletterConsent = b2x.storage.getBoolean('newsletterConsent', true);
    if (newsletterConsent) {
      return;
    }

    const modalClosedTimes = b2x.storage.getNumber('homePageNewsletterModalClosedTimes', true) ?? 0;
    if (modalClosedTimes < 2 && pageReady) {
      b2x.wait(30000 * (modalClosedTimes + 1)).then(showPopupNewsLetterModal);
    }
  }, [pageReady, showPopupNewsLetterModal]);

  const currentBreakpoint = b2x.useBreakpoint();

  return (
    <>
      <Page
        noPaddingBottom
        noPaddingTop
        thingsToLoadBeforePageReady={[page]}
        transparentHeader={!b2x.untilBreakpoint('lg', currentBreakpoint)}
      >
        {PopupNewsLetterModal}
        <BannerSlider {...page?.content?.body.sliderMainSection} />
        <SliderContentA {...page?.content?.body.productsContentSection} backgroundSection="bg-powder-light" />
        <BlocksRowSection {...page?.content?.body.blocksRowContent} />
        {page?.products && (
          <b2x.Listing name={`Home page - ${t('misc.ourSelection')}`} products={page.products}>
            <SliderProducts
              className="my-3 my-lg-5"
              products={page.products}
              title={t('misc.ourSelection')}
              titleClassName="fw-bold"
            />
          </b2x.Listing>
        )}
        {page?.content?.body.newsletterSection && (
          <div className="newsletter-animation position-relative d-none d-lg-block">
            {page.content.body.newsletterSection.img && (
              <div className="position-absolute h-100 w-100">
                <b2x.ImageAsBackgroundFromContent backgroundSize="cover" {...page.content.body.newsletterSection.img} />
              </div>
            )}
            <Container className="g-3 g-lg-5 py-3 py-lg-5">
              <b2x.Row className="justify-content-xl-center position-relative">
                {page.content.body.newsletterSection.lottie && page.content.body.newsletterSection.lottie.src && (
                  <div className="lottie-item position-absolute bottom-0 d-none d-lg-block h-100 mx-auto">
                    <LottieElement height="100%" src={page.content.body.newsletterSection.lottie.src} />
                  </div>
                )}
                <b2x.Col
                  className="newsletter-box offset-lg-4 offset-xl-0"
                  size={{ lg: 6, xl: 5, xs: 12 }}
                  style={{ zIndex: 1 }}
                >
                  {page.content.body.newsletterSection.title && (
                    <h3 className="h2 fw-bold text-center text-blue">
                      {b2x.formatHtml(page.content.body.newsletterSection.title)}
                    </h3>
                  )}
                  {page.content.body.newsletterSection.content && (
                    <p className="newsletter-box-content text-center">
                      {b2x.formatHtml(page.content.body.newsletterSection.content)}
                    </p>
                  )}
                  <NewsletterForm emailFieldStyle="inputGroup" source="website-footer" />
                </b2x.Col>
              </b2x.Row>
            </Container>
          </div>
        )}
      </Page>
    </>
  );
};
