import './RecipesPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { Breadcrumb } from '../Breadcrumb';
import { Button } from '../Button';
import { Container } from '../Container';
import { RecipePageContentType, RecipesPageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { ImageBanner } from '../ImageBanner';
import { Slider } from '../slider/Slider';
import { SliderButton } from '../slider/SliderButton';
import { Page } from './Page';

export interface RecipesPageProps {
  numRecipesShowed?: number;
}

export const RecipesPage = ({ numRecipesShowed = 6 }: RecipesPageProps) => {
  const sliderNextButton = React.useRef<HTMLButtonElement>(null);
  const sliderPrevButton = React.useRef<HTMLButtonElement>(null);

  const page = b2x.usePage<RecipesPageContentType>({ populate: { breadcrumb: true } });

  const menu = b2x.useMenu('MENU_RECIPES');
  const body = page?.content?.body;

  const { articles, fetchMore, fetching, noArticlesFound, otherItemsToShow } = b2x.useArticles<RecipePageContentType>({
    blogCodePage: 'SITE_RECIPES_GENTILINI',
    numArticlesShowed: numRecipesShowed,
  });

  const currentBreakpoint = b2x.useBreakpoint();

  return (
    <Page className="recipes-page" noPaddingTop transparentHeader={!b2x.untilBreakpoint('lg', currentBreakpoint)}>
      <ImageBanner {...body?.header} />
      {page?.breadcrumb && <Breadcrumb alignment="center" />}

      {(body?.pageTitle || body?.pageSubtitle) && (
        <Container>
          <div className="row justify-content-center my-3">
            <div className="col-md-10 col-lg-8 text-center text-blue">
              {body.pageTitle && <h1 className="mb-3 fw-bold">{b2x.formatHtml(body.pageTitle)}</h1>}
              {body.pageTitle && <h5>{b2x.formatHtml(body.pageSubtitle)}</h5>}
            </div>
          </div>
        </Container>
      )}

      {menu && (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text-center my-3">
                <h5 className="fw-bold text-blue">{t('misc.filterByProduct')}</h5>
              </div>
              <b2x.EqualHeight>
                <div className="menu-slider-container position-relative pb-2 pb-lg-5">
                  <Slider
                    className="pb-3"
                    navigation
                    responsive={{
                      lg: {
                        slidesPerView: menu.children.length < 6 ? menu.children.length : 6,
                      },
                      md: {
                        slidesPerView: menu.children.length < 4 ? menu.children.length : 4,
                      },

                      xl: {
                        slidesPerView: menu.children.length,
                      },
                    }}
                    slideContentCentered
                    sliderNextEl={sliderNextButton}
                    sliderPrevEl={sliderPrevButton}
                    slidesPerView={2}
                    spaceBetween={20}
                  >
                    {menu.children.map((item) => (
                      <SwiperSlide key={item.id}>
                        <div className="slide text-center">
                          {item.image?.src && (
                            <b2x.EqualHeightElement name="image">
                              <b2x.router.NavLink key={item.id} scrollTop={false} to={item.link}>
                                <b2x.Image className="mb-3" fluid src={item.image.src} />
                              </b2x.router.NavLink>
                            </b2x.EqualHeightElement>
                          )}

                          <b2x.EqualHeightElement name="title">
                            <b2x.router.NavLink
                              key={item.id}
                              scrollTop={false}
                              style={{ textDecoration: 'none' }}
                              to={item.link}
                            >
                              <h5 className="fw-bold mb-2 text-blue">{item.label}</h5>
                            </b2x.router.NavLink>
                          </b2x.EqualHeightElement>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Slider>

                  <SliderButton direction="left" innerRef={sliderPrevButton} size="small" />
                  <SliderButton direction="right" innerRef={sliderNextButton} size="small" />
                </div>
              </b2x.EqualHeight>
            </div>
          </div>
        </div>
      )}

      {articles && (
        <Container>
          <b2x.EqualHeight>
            <div className="row">
              {articles.map(
                (recipe) =>
                  recipe.breadcrumb && (
                    <div className="col-md-4" key={recipe.id}>
                      <RecipeTile
                        classname="mb-sm-5 mb-3"
                        cta={recipe.fullPath}
                        // difficulty={recipe.content?.body.difficulty}
                        img={recipe.content?.body.imagePreview}
                        tag={recipe.breadcrumb[recipe.breadcrumb.length - 2].name}
                        /*                         time={recipe.content?.body.time} */
                        title={recipe.content?.body.firstRow?.title}
                      />
                    </div>
                  )
              )}
            </div>
            {otherItemsToShow && (
              <div className="text-center mb-5">
                <Button
                  className="btn btn-brimary"
                  disabled={fetching}
                  label={t('misc.showMore')}
                  onClick={fetchMore}
                />
              </div>
            )}
          </b2x.EqualHeight>
        </Container>
      )}

      {noArticlesFound && (
        <Container className="my-5">
          <div className="row text-center">
            <div className="col">
              <h5 className="fw-bold text-blue">{t('misc.noItemFound')}</h5>
            </div>
          </div>
        </Container>
      )}

      {fetching && <div className="text-center my-3">Loading...</div>}
    </Page>
  );
};

interface RecipeTileProps {
  classname?: string;
  cta?: string;
  difficulty?: string;
  img?: b2x.contentTypes.ImageContentSection;
  tag?: string;
  time?: string;
  title?: string;
}

const RecipeTile = ({ classname, cta, difficulty, img, tag, time, title }: RecipeTileProps) => {
  return (
    <div className={classnames('recipe-tile text-center', classname)}>
      <b2x.ConditionalWrapper
        condition={cta !== undefined}
        wrapper={<b2x.router.Link style={{ display: 'block' }} to={cta} />}
      >
        <b2x.EqualHeightElement name="recipe-tile-image">
          <div className="recipe-tile-image">
            <b2x.ImageFromContent fluid {...img} />
          </div>
        </b2x.EqualHeightElement>
      </b2x.ConditionalWrapper>

      <div className="recipe-tile-content px-lg-2 py-2">
        {tag && <h6 className="mb-0 small">{tag}</h6>}

        {title && (
          <b2x.EqualHeightElement name="title">
            <b2x.ConditionalWrapper
              condition={cta !== undefined}
              wrapper={<b2x.router.Link style={{ textDecoration: 'none' }} to={cta} />}
            >
              <h5 className="fw-bold mb-0 mt-1 text-blue">{b2x.formatHtml(title)}</h5>
            </b2x.ConditionalWrapper>
          </b2x.EqualHeightElement>
        )}

        {/*         <div className="hstack mt-4 align-middle gap-3 justify-content-center">
          <div>
            {time && (
              <Button
                alignItems="center"
                iconStart={{ className: 'text-secondary', name: 'download', size: 30 }}
                label={<h5 className="mb-0">{time}</h5>}
                size="small"
                variant="blank"
              />
            )}
          </div>
          <div>
            {difficulty && (
              <Button
                alignItems="center"
                iconStart={{ className: 'text-secondary', name: 'chef', size: 30 }}
                label={<h5 className="mb-0">{difficulty}</h5>}
                size="small"
                variant="blank"
              />
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
};
