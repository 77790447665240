import { b2x } from '@b2x/react/src';
import React from 'react';

import { Container } from '../Container';
import { iconsNames } from '../Icon';
import { Page } from './Page';

export const TemplatePage = () => {
  const page = b2x.usePage({
    populate: {
      content: false,
    },
  });

  const { sortingOptions } = b2x.useSortingOptions([
    { orderBy: 'PRIORITY', orderingType: 'ASC' },
    { orderBy: 'PRIORITY', orderingType: 'DESC' },
    { orderBy: 'NAME', orderingType: 'ASC' },
    { orderBy: 'NAME', orderingType: 'DESC' },
    { orderBy: 'PRICE', orderingType: 'ASC' },
    { orderBy: 'PRICE', orderingType: 'DESC' },
    { orderBy: 'ID', orderingType: 'ASC' },
    { orderBy: 'ID', orderingType: 'DESC' },
  ]);

  return (
    <Page thingsToLoadBeforePageReady={[page]}>
      <TemplateSection title="Grid System">
        <b2x.template.GridSystem />
      </TemplateSection>

      <TemplateSection title="Typography">
        <b2x.template.Typography
          variants={[
            { className: 'display-1', name: 'Display 1' },
            { className: 'h1', name: 'Heading 1' },
            { className: 'h2', name: 'Heading 2' },
            { className: 'h3', name: 'Heading 3' },
            { className: 'h4', name: 'Heading 4' },
            { className: 'h5', name: 'Heading 5' },
            { className: 'h6', name: 'Heading 6' },
            { className: 'fw-bold', name: 'Bold text' },
            { className: 'fw-normal', name: 'Normal text' },
            { className: 'small ', name: 'Small text' },
            { className: 'extra-small', name: 'Extra small text' },
          ]}
        />
      </TemplateSection>

      <TemplateSection title="Icons">
        <b2x.template.Icons names={iconsNames} />
      </TemplateSection>

      <TemplateSection title="Spacing">
        <b2x.template.Spacing />
      </TemplateSection>

      <TemplateSection title="Colors">
        <b2x.template.Colors
          colors={[
            { background: 'primary', text: 'white' },
            { background: 'secondary', text: 'white' },
            { background: 'red', text: 'white' },
            { background: 'blue', text: 'white' },
            { background: 'blue-light', text: 'white' },
            { background: 'green-dark', text: 'white' },
            { background: 'green-light', text: 'white' },
            { background: 'gray-100', text: 'black' },
            { background: 'gray-200', text: 'black' },
            { background: 'gray-300', text: 'black' },
            { background: 'gray-400', text: 'black' },
            { background: 'gray-500', text: 'black' },
            { background: 'orange-light', text: 'black' },
            { background: 'powder', text: 'black' },
            { background: 'powder-light', text: 'black' },
          ]}
        />
      </TemplateSection>

      <TemplateSection title="Form">
        <Container>
          <b2x.ExampleForm />
        </Container>
      </TemplateSection>

      <TemplateSection title="Buttons">
        <b2x.template.Buttons
          backgrounds={['white']}
          iconsOrLabels={['onlyLabel', 'onlyIcon', 'IconLeftAndLabel', 'IconRightAndLabel']}
          sizes={[undefined]}
          variants={['primary', 'secondary']}
        />
      </TemplateSection>

      <TemplateSection title="Dropdowns">
        <Container>
          <div className="row">
            <b2x.template.Dropdowns
              hideAlignments
              hideAutoClose
              hideComponent
              hideDarkVariant
              hideDirections
              hideOffsets
              sizes={[undefined]}
              variants={['primary', 'secondary']}
            />
          </div>
        </Container>
      </TemplateSection>

      <TemplateSection title="Table">
        <Container>
          <b2x.Table border="light" className="border" colNumber={10} responsive xAlignment="center">
            <b2x.TableHead variant="light">
              <tr>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((th) => (
                  <th key={th}>th{th}</th>
                ))}
              </tr>
            </b2x.TableHead>
            <b2x.TableBody>
              {[1, 2, 3, 4, 5].map((tr) => (
                <tr key={tr}>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((td) => (
                    <td key={td}>
                      tr{tr} td{td}
                    </td>
                  ))}
                </tr>
              ))}
            </b2x.TableBody>
          </b2x.Table>
        </Container>
      </TemplateSection>

      <TemplateSection title="Tabs">
        <Container>
          <b2x.template.Tabs />
        </Container>
      </TemplateSection>

      <TemplateSection title="Accordion">
        <Container>
          <b2x.Accordion id="AccordionExample">
            <b2x.AccordionItem id="One" title="Title ONE">
              <b2x.LoremIpsum />
            </b2x.AccordionItem>
            <b2x.AccordionItem id="Two" title="Title Two">
              <b2x.LoremIpsum />
            </b2x.AccordionItem>
          </b2x.Accordion>
        </Container>
      </TemplateSection>

      <TemplateSection title="Modals">
        <Container>
          <b2x.template.Modals />
        </Container>
      </TemplateSection>

      <TemplateSection title="Offcanvas">
        <Container>
          <b2x.template.Offcanvasses />
        </Container>
      </TemplateSection>

      <TemplateSection title="Pagination">
        <Container>
          <b2x.template.Paginations />
        </Container>
      </TemplateSection>

      <TemplateSection title="Product Tiles">
        <div className="container-fluid">
          {page?.products && (
            <b2x.Listing name="Template page - Product Tiles" products={page.products}>
              <b2x.ProductsTiles
                enableExtraInfo
                products={page.products.map((product) => ({ product: product }))}
                productsPerRow={{ lg: 4, md: 2, sm: 2, xl: 4, xs: 2 }}
              />
            </b2x.Listing>
          )}
        </div>
      </TemplateSection>

      <TemplateSection title="Ordinamento prodotti">
        <Container>
          <b2x.Dropdown label="Ordina per">
            {sortingOptions.map((sortingOption) => (
              <b2x.DropdownItem active={sortingOption.active} key={sortingOption.label} onClick={sortingOption.onClick}>
                {sortingOption.label}
              </b2x.DropdownItem>
            ))}
          </b2x.Dropdown>
        </Container>
      </TemplateSection>
    </Page>
  );
};

interface TemplateSectionProps {
  children?: React.ReactNode;
  title?: string;
}

const TemplateSection = ({ children, title }: TemplateSectionProps) => {
  return (
    <section className="my-5">
      {title && (
        <Container>
          <div className="row">
            <div className="col-12">
              <h1 className="mb-4">{title}</h1>
            </div>
          </div>
        </Container>
      )}
      {children}
    </section>
  );
};
