import './BlocksRowSection.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import { SwiperSlide } from 'swiper/react';

import { BlocksRowContent } from './contentTypes';
import { LottieElement } from './LottieElement';
import { Slider } from './slider/Slider';
export interface BlocksRowSectionProps extends BlocksRowContent {}

export const BlocksRowSection = ({ rowsList, title }: BlocksRowContent) => {
  return (
    <div className="blocks-row-section bg-gray-200 pt-5">
      <div className="container-fluid container-fluid-w-custom g-0">
        {title && (
          <div className="row g-0">
            <div className="text-center pb-lg-5 pb-4 px-3">
              <h2 className="text-blue fw-bold">{b2x.formatHtml(title)}</h2>
            </div>
          </div>
        )}

        {rowsList &&
          rowsList.map((itemRow, index) => (
            /*  <div className="row g-0" key={itemRow.contentSectionId}>
              <div className={classnames('col-lg-6', { 'order-lg-2': itemRow.contentTextAlignment === 'left' })}>
                <b2x.ImageFromContent {...itemRow.img} fluid />
              </div>
              <div className={classnames('col-lg-6', { 'order-lg-1': itemRow.contentTextAlignment === 'left' })}>
                <div
                  className="h-100 py-3 py-lg-0 d-lg-flex align-items-center"
                  style={
                    itemRow.backgroundContentImage
                      ? {
                          backgroundImage: `url("${itemRow.backgroundContentImage}")`,
                          backgroundRepeat: 'repeat',
                          backgroundSize: 'auto',
                        }
                      : undefined
                  }
                >
                  <div className="pe-lg-5">
                    <div className="row justify-content-center g-0">
                      <div className="col-lg-12 col-xl-10 px-3 px-lg-5">
                        {itemRow.title && (
                          <h3 className="text-blue h2 mb-4 text-center fw-bold">{b2x.formatHtml(itemRow.title)}</h3>
                        )}
                        {itemRow.content && (
                          <div className="text-lg-start text-center mb-5">{b2x.formatHtml(itemRow.content)}</div>
                        )}
                      </div>
                      {itemRow.productsList && (
                        <div className="col-lg-12 col-xl-10 px-0 px-lg-5">
                          <ProductsHomeSlider productsContent={itemRow.productsList} />
                        </div>
                      )}
                      {itemRow.cta && itemRow.cta.label && (
                        <div className="col-lg-12 col-xl-10 px-3 px-lg-5">
                          <div className="text-center my-4">
                            <b2x.DeprecatedCta
                              className={classnames('btn text-white text-uppercase', `btn-${itemRow.cta.variant}`)}
                              cta={itemRow.cta}
                            >
                              {b2x.formatHtml(itemRow.cta.label)}
                            </b2x.DeprecatedCta>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {itemRow.centralAnimation?.lottie && itemRow.centralAnimation.lottie.src && (
                <div className="position-absolute">
                  <LottieElement
                    height={itemRow.centralAnimation.lottie.height}
                    id={`lottie-${index}`}
                    src={itemRow.centralAnimation.lottie.src}
                    width={itemRow.centralAnimation.lottie.width}
                  />
                </div>
              )}
            </div> */

            <RowItem {...itemRow} key={itemRow.contentSectionId} />
          ))}
      </div>
    </div>
  );
};

interface RowItemProps {
  backgroundContentImage?: b2x.contentTypes.Image;
  centralAnimation?: {
    img?: b2x.contentTypes.ImageContentSection;
    lottie?: {
      pxHeight?: number;
      src?: string;
    };
    video?: b2x.contentTypes.VideoAsBackgroundContentSectionDeprecated;
  };
  content?: string;
  contentTextAlignment?: string;
  cta?: b2x.contentTypes.CtaContentSection;
  img?: b2x.contentTypes.ImageContentSection;
  productsList?: b2x.contentTypes.ContentSectionList<{
    cta?: b2x.contentTypes.CtaContentSection;
    img?: b2x.contentTypes.ImageContentSection;
  }>;
  title?: string;
}

const RowItem = ({ ...props }: RowItemProps) => (
  <div className="row g-0 position-relative">
    <div className={classnames('col-lg-6', { 'order-lg-2': props.contentTextAlignment === 'left' })}>
      <b2x.ImageFromContent {...props.img} fluid />
    </div>
    <div className={classnames('col-lg-6', { 'order-lg-1': props.contentTextAlignment === 'left' })}>
      <div
        className="h-100 py-3 py-lg-0 d-lg-flex align-items-center"
        style={
          props.backgroundContentImage
            ? {
                backgroundImage: `url("${props.backgroundContentImage.url}")`,
                backgroundRepeat: 'repeat',
                backgroundSize: 'auto',
              }
            : undefined
        }
      >
        <div className="pe-lg-5">
          <div className="row justify-content-center g-0">
            <div className="col-lg-12 col-xl-10 px-3 px-lg-5">
              {props.title && <h3 className="text-blue h2 mb-4 text-center fw-bold">{b2x.formatHtml(props.title)}</h3>}
              {props.content && <div className="text-lg-start text-center mb-5">{b2x.formatHtml(props.content)}</div>}
            </div>
            {props.productsList && (
              <div className="col-lg-12 col-xl-10 px-0 px-lg-5">
                <ProductsHomeSlider productsContent={props.productsList} />
              </div>
            )}
            {props.cta && props.cta.label && (
              <div className="col-lg-12 col-xl-10 px-3 px-lg-5">
                <div className="text-center my-4">
                  <b2x.DeprecatedCta
                    className={classnames('btn text-white', `btn-${props.cta.variant}`)}
                    cta={props.cta}
                  >
                    {b2x.formatHtml(props.cta.label)}
                  </b2x.DeprecatedCta>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    {props.centralAnimation?.lottie && props.centralAnimation.lottie.src && (
      <div
        className="position-absolute bottom-0 d-none d-lg-block h-100 mx-auto start-0 end-0"
        style={{ pointerEvents: 'none', width: 'fit-content' }}
      >
        <LottieElement height="100%" src={props.centralAnimation.lottie.src} />
      </div>
    )}
  </div>
);

interface ProductsHomeSliderProps {
  productsContent?: b2x.contentTypes.ContentSectionList<{
    cta?: b2x.contentTypes.CtaContentSection;
    img?: b2x.contentTypes.ImageContentSection;
  }>;
}
const ProductsHomeSlider = ({ productsContent }: ProductsHomeSliderProps) => {
  return (
    <div>
      {productsContent && (
        <>
          <div className="d-none d-md-flex hstack gap-5 justify-content-center">
            {productsContent.map((item) =>
              item.cta ? (
                <b2x.DeprecatedCta cta={item.cta} key={item.contentSectionId}>
                  <b2x.ImageFromContent {...item.img} fluid />
                </b2x.DeprecatedCta>
              ) : (
                <b2x.ImageFromContent {...item.img} fluid key={item.contentSectionId} />
              )
            )}
          </div>

          <div className="d-md-none">
            <Slider
              centeredSlides
              loop
              responsive={{
                sm: {
                  slidesPerView: 2.4,
                },
              }}
              slidesPerView={1.8}
              spaceBetween={10}
            >
              {productsContent.map((product) => (
                <SwiperSlide key={product.contentSectionId}>
                  <div className="product-slide text-center">
                    {product.cta ? (
                      <b2x.DeprecatedCta cta={product.cta}>
                        <b2x.ImageFromContent {...product.img} fluid />
                      </b2x.DeprecatedCta>
                    ) : (
                      <b2x.ImageFromContent {...product.img} fluid />
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Slider>
          </div>
        </>
      )}
    </div>
  );
};
